import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const EndfieldGuidesIntroPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page endfield'} game="endfield">
      <ul className="breadcrumb">
        <li>
          <Link to="/arknights-endfield/">Arknights: Endfield</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/arknights-endfield/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Introduction to the game</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/endfield/categories/category_intro.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Introduction to the game</h1>
          <h2>
            Everything you need to know about Arknights: Endfield gacha game.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="About the game" />
        <p>
          Arknights: Endfield is a real-time 3D RPG with strategic elements by
          HYPERGRYPH. Take up the role of the Protocol Field Recovery Department
          Endministrator, clear your assignments, and explore the frontiers.
        </p>
        <p>
          <i>
            "Complete this contract and we'll leave our home for a new world of
            endless possibilities - a new frontier awaits our discovery."
          </i>
          Welcome to Talos-II and your new home at Endfield Industries. A new
          era of change has dawned upon this ancient world. Time to make your
          choice.
        </p>
        <SectionHeader title="Official links" />
        <ul>
          <li>
            Official Website:{' '}
            <a
              href="https://endfield.gryphline.com"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Official Discord:{' '}
            <a
              href="https://discord.com/invite/akendfield"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Official YouTube:{' '}
            <a
              href="https://www.youtube.com/@arknightsendfieldEN"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Official Twitter:{' '}
            <a
              href="https://twitter.com/AKEndfield"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>Google Play Store: -</li>
          <li>Apple Store: -</li>
        </ul>
        <SectionHeader title="Video" />
        <p>If you want to see the gameplay, check the videos below:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="2h85NtIwduY" />
          </Col>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="JcsOm6WSBDA" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default EndfieldGuidesIntroPage;

export const Head: React.FC = () => (
  <Seo
    title="Introduction to the game | Arknights: Endfield | Prydwen Institute"
    description="Everything you need to know about Arknights: Endfieldy gacha game."
    game="endfield"
  />
);
